




























import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
import {ArticleService} from '@/services/article.service';
import {Article, Headline, IndexedArticles, IndexedKeywords} from '@/model/article/article.model';
import {nil} from '@/utils/common.util';
import NewsHeadlines from '@/components/news/NewsHeadlines.vue';
import NewsArticle from '@/components/news/NewsArticle.vue';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import NewsCarousel from '@/components/news/NewsCarousel.vue';
import PanelBox from '@/components/ui/PanelBox.vue';
import {Box, HfPage, ImageDescription} from '@/model/site/components.model';
import {Context} from '@/model/site/context.model';
import {Getter} from 'vuex-class';
import {CONTEXT, FOCUS_DATE, NEWS_CONFIG, SET_FOCUS_DATE} from '@/store/context.store';
import {PageService} from '@/services/page.service';
import {NewsPageData} from '@/model/site/page.model';
import S from 'string';
import Loading from 'vue-loading-overlay';
import {
  HEADLINES_AT_DATE,
  HEADLINES_DATE,
  LATEST_HEADLINES,
  OTHER_NEWS,
  PLAYER_NEWS,
  TEAM_NEWS
} from '@/store/data/getters';
import {
  SET_HEADLINES_AT_DATE,
  SET_LATEST_HEADLINES,
  SET_OTHER_NEWS,
  SET_PLAYER_NEWS,
  SET_TEAM_NEWS
} from '@/store/data/mutations';
import dayjs from 'dayjs';
import NewsColumns from '@/components/news/NewsColumns.vue';
import MetaMixin from '@/mixins/meta.mixin';
import RouteMixin from '@/mixins/route.mixin';
import NewsHeadlinesCalendar from '@/components/news/NewsHeadlinesCalendar.vue';
import SportLayout from '@/components/layout/SportLayout.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import NewsHeadlinesKeyword from '@/components/news/NewsHeadlinesKeyword.vue';
import NewsHeadline from '@/components/news/NewsHeadline.vue';
import MobileMixin from '@/mixins/mobile.mixin';
import GoldbachOutstream from '@/components/ads/GoldbachOutstream.vue';
import RefreshPageMixin, {HfRefreshablePage} from '@/mixins/refreshable-page.mixin';

@Component({
  components: {
    GoldbachOutstream,
    NewsHeadline,
    SideBoxes,
    NewsColumns,
    SportLayout,
    PanelBox,
    NewsCarousel,
    HfAdBox,
    NewsArticle,
    NewsHeadlines,
    NewsHeadlinesKeyword,
    Loading
  },
  mixins: [MetaMixin, RouteMixin, MobileMixin, RefreshPageMixin],
  metaInfo() {
    const metaInfo = this.metaInfo;
    return this.replaceMetaProperty(metaInfo, 'og:type', 'article');
  },
})
export default class NewsPage extends Vue implements HfRefreshablePage {

  @Getter(CONTEXT) context!: Context;
  @Getter(NEWS_CONFIG) newsConfig!: any;
  @Getter(HEADLINES_AT_DATE) headlinesAtDate!: Headline[];
  @Getter(HEADLINES_DATE) headlinesDate!: string;
  @Getter(FOCUS_DATE) focusDate!: Date;
  @Getter(LATEST_HEADLINES) latestHeadlines!: Headline[];
  @Getter(PLAYER_NEWS) playerNews!: IndexedArticles[];
  @Getter(TEAM_NEWS) teamNews!: IndexedArticles[];
  @Getter(OTHER_NEWS) otherNews!: Article[];

  @Prop(String) id?: number;
  @Inject('articleService') articleService!: ArticleService;
  @Inject('pageService') pageService!: PageService;

  focus: Article = nil();
  relevantKeywords: IndexedKeywords = nil();
  topicNews: IndexedArticles = nil();

  get headTitle(): string {
    if (this.focus) {
      // recommended max-length is 65, but I think this is too short
      return `${S(this.focus.title).truncate(100)} | News`;
    }
    return `Aktuelle News`;
  }

  get headDescription(): string {
    if (this.focus) {
      return this.focus.lead;
    }
    return '';
  }

  get canonicalUrl(): string {
    return this.urlFromCurrentPath;
  }

  get imageDesc(): ImageDescription | undefined {
    if (this.focus) {
      return this.articleService.imageDesc(this.focus);
    }
    return undefined;
  }

  get moreNews() {
    return this.topicNews;
  }

  get boxes(): Box[] {
    const latestHeadlines: Box = {
      title: 'Aktuelle Schlagzeilen',
      component: NewsHeadlines,
      props: {headlines: this.latestHeadlines},
    };
    const ad: Box = {
      component: HfAdBox,
      props: {formatKey: 'rectangle', targets: ['desktop']},
    };
    const dateHeadlines: Box = {
      title: `News vom ...`,
      component: NewsHeadlinesCalendar,
      props: {headlinesAtDate: this.headlinesAtDate, noMarginTop: true},
    };
    let boxes;
    if (this.isMobile()) {
      boxes = [dateHeadlines];
    } else {
      boxes = [latestHeadlines, ad, dateHeadlines];
    }

    const relevantNews = {...this.teamNews, ...this.playerNews};
    if (relevantNews && Object.keys(relevantNews).length) {
      const relevantHeadlines: Box = {
        title: 'Weitere News zu ...',
        component: NewsHeadlinesKeyword,
        props: {headlines: relevantNews, keywords: this.relevantKeywords},
      };
      boxes.splice(1, 0, relevantHeadlines);
    }
    return boxes;
  }

  created() {
    this.refreshPage();
  }

  updated() {
    // avoid "random"-scroll after updatePageData
    this.$nextTick(function () {
      window.scrollTo(0, 0);
    });
  }

  refreshPage() {
    const detail = this.id ? this.articleService.detail(this.id) : this.articleService.latestFocus();
    detail.then((article: Article) => {
      this.setFocus(article);
    }).catch(() => {
    });
  }

  setFocus(data: Article) {
    this.focus = data;
    const newFocusDate = dayjs(data.createdAt);
    if (!newFocusDate.isValid()) {
      Vue.$log.warn('Invalid date on NewsPage', data.createdAt);
    } else if (!newFocusDate.isSame(dayjs(this.focusDate), 'day')) {
      this.$store.commit(SET_FOCUS_DATE, newFocusDate.toDate());
    }
    // TODO route update with canonical url
    // FIXME avoid re-fetch
    this.updatePageData(this.focus.id, this.newsConfig);
  }

  updatePageData(id: number, newsConfig: any) {
    this.$wait.start('waitingNewsPage');
    this.pageService.newsPage(id, newsConfig).then((data: NewsPageData) => {
      this.$store.commit(SET_PLAYER_NEWS, data.playerNews || {});
      this.$store.commit(SET_TEAM_NEWS, data.teamNews || {});
      this.$store.commit(SET_OTHER_NEWS, data.otherNews);
      this.$store.commit(SET_LATEST_HEADLINES, data.latestHeadlines);
      this.$store.commit(SET_HEADLINES_AT_DATE, data.dateHeadlines);
      this.relevantKeywords = data.relevantKeywords;
      this.topicNews = data.topicNews;
    })
      .catch((error: any) => Vue.$log.warn('Error fetching data for news-page', error))
      .finally(() => this.$wait.end('waitingNewsPage'));
  }

}

