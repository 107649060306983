






















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Headline, IndexedHeadlines} from '@/model/article/article.model';
import PanelBox from '@/components/ui/PanelBox.vue';
import DateFilters from '@/mixins/date-filters.mixin';
import {RawLocation} from 'vue-router';

@Component({
  components: {PanelBox},
  mixins: [DateFilters],
})
export default class NewsHeadlinesKeyword extends Vue {
  @Prop(Object) headlines!: IndexedHeadlines;
  @Prop(Object) keywords!: any; // IndexedHeadlines

  link(headline: Headline): RawLocation {
    return {name: 'route-news-id', params: {id: headline.id.toString()}};
  }

  subtitle(key: string) {
    const keyword: any = (this.keywords ? this.keywords[key] : undefined);
    if (keyword) {
      return keyword.resolved || keyword.value.toUpperCase();
    }
    return key.toUpperCase();
  }
}
