
















import {Component, Prop, Vue} from 'vue-property-decorator';
import NewsArticle from '@/components/news/NewsArticle.vue';
import {Carousel, Slide} from '@noim/vue-carousel';
import {Article} from '@/model/article/article.model';


@Component({
  components: {NewsArticle, Carousel, Slide},
})
export default class NewsCarousel extends Vue {
  @Prop(Array) articles!: Article[];
}
