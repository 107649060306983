












import {Component, Vue} from 'vue-property-decorator';
import {Prop} from 'vue-property-decorator';
import {Headline} from '@/model/article/article.model';
import NewsHeadlines from '@/components/news/NewsHeadlines.vue';
import NewsCalendar from '@/components/news/NewsCalendar.vue';

@Component({
    components: {NewsCalendar, NewsHeadlines},
})
export default class NewsHeadlinesCalendar extends Vue {
  @Prop(Array) headlinesAtDate!: Headline[];

}
