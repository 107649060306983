










import {Component, Inject, Vue} from 'vue-property-decorator';
import {ArticleService} from '@/services/article.service';
import {HEADLINES_DATE} from '@/store/data/getters';
import {SET_HEADLINES_AT_DATE} from '@/store/data/mutations';
import {Getter} from 'vuex-class';
import {HfCalendar} from '@/model/site/components.model';
import CalendarComponent from '@/components/shared/CalendarComponent.vue';
import {Headline} from '@/model/article/article.model';

@Component({
  components: {CalendarComponent},
})
export default class NewsCalendar extends Vue implements HfCalendar {

  @Getter(HEADLINES_DATE) headlinesDate?: Date;
  @Inject('articleService') articleService!: ArticleService;

  selectedDate = new Date();
  isLoading = false;
  events: Date[] = [];

  _year?: number;
  _month?: number;

  calendar: HfCalendar = this;

  get year(): number {
    return this._year || this.selectedDate.getFullYear();
  }

  get month(): number {
    return this._month || this.selectedDate.getMonth();
  }

  get minDate(): Date {
    // months start at 0
    return new Date(1998, 6, 13);
  }

  get maxDate(): Date {
    return new Date();
  }

  onDateChange(date: Date): void {
    const dateStr = date ? date.toDateString() : '';
    const calStr = this.calendarDate ? this.calendarDate.toDateString() : '';
    if (dateStr !== calStr) {
      this.isLoading = true;
      this.articleService.headlines(date)
        .then((headlinesAtDate: Headline[]) => {
          this.isLoading = false;
          this.$store.commit(SET_HEADLINES_AT_DATE, headlinesAtDate);
        });
    }
  }

  created() {
    this.onMonthYearChange(this.year, this.month);
  }

  onMonthChange(month: number): void {
    this.onMonthYearChange(this.year, month);
  }

  onYearChange(year: number): void {
    this.onMonthYearChange(year, this.month);
  }

  onMonthYearChange(year: number, month: number): void {
    this.articleService.dates(year, month)
      .then((dates: Date[]) => {
        // this.events = dates;
        return this.events.splice(0, this.events.length, ...dates);
      });
  }

}
